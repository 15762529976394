import { MatchUniverse } from '../state/clientModels';
import { BALL_RADIUS } from '../../universal/constants';
import * as V from '../../universal/vectorMaths';

const METER_WIDTH = 20;
const METER_HEIGHT = 5;
const METER_OFFSET_Y = BALL_RADIUS * 2;
const AIM_BAR_OFFSET = 10;
const AIM_BAR_LENGTH = 10;

export function drawShotUI(
  ctx: CanvasRenderingContext2D,
  universe: MatchUniverse
): void {
  if (!universe.match.inputState.canShoot) {
    return;
  }

  const player = universe.currentPlayer!;
  const ball = universe.match.balls[player.id];
  if (!ball) {
    return;
  }

  // draw aim bar
  const aimDirection = universe.match.inputState.aimDirection;
  const start = V.rotate({ x: AIM_BAR_OFFSET, y: 0 }, aimDirection);
  const end = V.rotate(
    { x: AIM_BAR_OFFSET + AIM_BAR_LENGTH, y: 0 },
    aimDirection
  );
  ctx.beginPath();
  ctx.lineWidth = 2;
  ctx.strokeStyle = 'white';
  ctx.moveTo(ball.position.x + start.x, ball.position.y + start.y);
  ctx.lineTo(ball.position.x + end.x, ball.position.y + end.y);
  ctx.stroke();
  ctx.closePath();

  // draw power bar when shooting
  if (!universe.match.inputState.shooting) {
    return;
  }

  ctx.strokeStyle = 'white';
  const rx = ball.position.x - METER_WIDTH / 2;
  const ry = ball.position.y + METER_HEIGHT / 2 + METER_OFFSET_Y;
  ctx.fillStyle = 'black';
  ctx.fillRect(rx, ry, METER_WIDTH, METER_HEIGHT);
  ctx.strokeRect(rx, ry, METER_WIDTH, METER_HEIGHT);

  ctx.fillStyle = 'white';
  const filled = universe.match.inputState.shotPower;
  const filledWidth = METER_WIDTH * filled;
  ctx.fillRect(rx, ry, filledWidth, METER_HEIGHT);
}
