import * as t from 'io-ts';
import { PathReporter } from 'io-ts/lib/PathReporter';
import { isLeft } from 'fp-ts/lib/Either';

const VectorC = t.type({
  x: t.number,
  y: t.number,
});

const LevelC = t.type({
  points: t.array(VectorC),
  hole: VectorC,
  spawn: VectorC,
  terrainColor: t.string,
});

const PlayerC = t.type({
  id: t.string,
  name: t.string,
  color: t.string,
});

/**
 * Contains whatever is needed to sync the ball physics.
 */
const BallPhysicsSnapshotC = t.type({
  playerId: t.string,
  position: VectorC,
  linearVelocity: VectorC,
  angle: t.number,
  angularVelocity: t.number,
  canShoot: t.boolean,
});
export type BallPhysicsSnapshot = t.TypeOf<typeof BallPhysicsSnapshotC>;

const ServerInitialSnapshotMessageC = t.type({
  type: t.literal('initialSnapshot'),
  frame: t.number,
  balls: t.array(BallPhysicsSnapshotC),
  level: LevelC,
  players: t.array(PlayerC),
});
export type ServerInitialSnapshotMessage = t.TypeOf<
  typeof ServerInitialSnapshotMessageC
>;

const ServerUpdateMessageC = t.type({
  type: t.literal('update'),
  frame: t.number,
  balls: t.array(BallPhysicsSnapshotC),
});
export type ServerUpdateMessage = t.TypeOf<typeof ServerUpdateMessageC>;

/** Let the current player know who they are */
const ServerIdentityMessageC = t.type({
  type: t.literal('identity'),
  playerId: t.string,
  name: t.string,
});
export type ServerIdentityMessage = t.TypeOf<typeof ServerIdentityMessageC>;

/** Sent when a player joins. */
const ServerJoinedMessageC = t.type({
  type: t.literal('joined'),
  player: PlayerC,
  ball: BallPhysicsSnapshotC,
});
export type ServerJoinedMessage = t.TypeOf<typeof ServerJoinedMessageC>;

/** Sent when a player leaves. */
const ServerLeftMessageC = t.type({
  type: t.literal('left'),
  playerId: t.string,
});
export type ServerLeftMessage = t.TypeOf<typeof ServerLeftMessageC>;

/** Sent when a player shoots. */
const ServerShotMessageC = t.type({
  type: t.literal('shot'),
  playerId: t.string,
  angle: t.number,
  power: t.number,
  position: VectorC,
});
export type ServerShotMessage = t.TypeOf<typeof ServerShotMessageC>;

const ServerScoredMessageC = t.type({
  type: t.literal('scored'),
  playerId: t.string,
});
export type ServerScoredMessage = t.TypeOf<typeof ServerScoredMessageC>;

const ServerPongMessageC = t.type({
  type: t.literal('pong'),
});
export type ServerPongMessage = t.TypeOf<typeof ServerPongMessageC>;

const ServerMessageC = t.union([
  ServerInitialSnapshotMessageC,
  ServerUpdateMessageC,
  ServerIdentityMessageC,
  ServerJoinedMessageC,
  ServerLeftMessageC,
  ServerShotMessageC,
  ServerScoredMessageC,
  ServerPongMessageC,
]);
export type ServerMessage = t.TypeOf<typeof ServerMessageC>;

export function parseServerMessage(data: unknown): ServerMessage | null {
  const result = ServerMessageC.decode(data);

  if (isLeft(result)) {
    const errors = PathReporter.report(result);
    console.warn('parsing errors:', errors);
    return null;
  }

  return result.right;
}
