import { Universe } from '../clientModels';
import { ServerIdentityMessage } from '../../../universal/messages/ServerMessage';

export function updateHandleIdentity(
  universe: Universe,
  msg: ServerIdentityMessage
): void {
  universe.currentPlayer = {
    id: msg.playerId,
    name: msg.name,
  };
}
