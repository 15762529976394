import { Universe } from '../state/clientModels';
import { GAME_WIDTH, GAME_HEIGHT } from '../../universal/constants';
import { drawGround } from './drawGround';
import { drawBall } from './drawBall';
import { drawShotUI } from './drawShotUI';
import { drawPing } from './drawPing';

export function drawErrorScreen(
  ctx: CanvasRenderingContext2D,
  err: Error
): void {
  ctx.fillStyle = 'black';
  ctx.fillRect(0, 0, GAME_WIDTH, GAME_HEIGHT);

  ctx.font = '20px monospace';
  ctx.textAlign = 'center';
  ctx.fillStyle = 'white';
  ctx.fillText('error :(', GAME_WIDTH / 2, GAME_HEIGHT / 2);
  ctx.font = '14px monospace';
  ctx.fillText(err.message, GAME_WIDTH / 2, GAME_HEIGHT / 2 + 20);
}
