import { ServerJoinedMessage } from '../../../universal/messages/ServerMessage';
import { MatchUniverse } from '../clientModels';

export function updateHandleJoined(
  universe: MatchUniverse,
  msg: ServerJoinedMessage
): void {
  universe.match.players[msg.player.id] = msg.player;
  universe.match.balls[msg.ball.playerId] = msg.ball;
}
