import { FIXED_STEP } from '../../universal/constants';

export abstract class Runner {
  stopped = false;

  abstract fixedUpdate(dt: number): void;
  abstract render(lagOffset: number): void;
  abstract handleError(err: Error): void;

  run(): void {
    if (this.stopped) {
      // stop run loop
      return;
    }

    let lastTime = performance.now();
    let lag = 0;

    /**
     * The "real" (RAF-bound) run loop.
     */
    const loop = (): void => {
      try {
        // Compute delta and elapsed time
        const time = performance.now();
        const delta = time - lastTime;

        if (delta > 1000) {
          // TODO: if this happens... might have other options? idk
          throw new Error('unrecoverable time delta');
        }
        lag += delta;

        while (lag >= FIXED_STEP) {
          this.fixedUpdate(FIXED_STEP);
          lag -= FIXED_STEP;
        }

        const lagOffset = lag / FIXED_STEP;
        this.render(lagOffset);

        lastTime = time;
        requestAnimationFrame(loop);
      } catch (err) {
        this.handleError(err);
      }
    };

    loop();
  }

  stop(): void {
    this.stopped = true;
  }
}
