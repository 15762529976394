import { Runner } from './util/Runner';
import { Inputter } from './util/Inputter';
import { Universe, initUniverse, MatchUniverse } from './state/clientModels';
import { draw } from './draw';
import { updateTick } from './state/update/updateTick';
import { Effect } from './state/effects';
import { Socket } from './socket/Socket';
import { ServerMessage } from '../universal/messages/ServerMessage';
import { updateHandleIdentity } from './state/update/updateHandleIdentity';
import {
  updateHandleInitialSnapshot,
  updateHandleUpdateSnapshot,
} from './state/update/updateHandleSnapshot';
import { updateHandleJoined } from './state/update/updateHandleJoined';
import { updateHandleLeft } from './state/update/updateHandleLeft';
import { getScale } from './util/getScale';
import { drawErrorScreen } from './draw/drawErrorScreen';

export class Game extends Runner {
  ctx: CanvasRenderingContext2D;
  universe: Universe;
  inputter: Inputter;
  socket: Socket;

  constructor() {
    super();
    const canvas = document.querySelector('canvas');
    if (!canvas) {
      throw new Error('failed to find canvas on page');
    }

    canvas.width = window.innerWidth;
    canvas.height = window.innerHeight;
    this.ctx = canvas.getContext('2d') as CanvasRenderingContext2D;
    const scale = getScale();
    this.ctx.scale(scale, scale);

    this.inputter = new Inputter();
    this.inputter.bind(canvas);

    this.universe = initUniverse();

    this.socket = new Socket();
    this.socket.connect();
    this.socket.bindOnMessage(this.handleMessage);
  }

  fixedUpdate(dt: number): void {
    const inputs = this.inputter.getInputState();

    const effects = updateTick(this.universe, dt, inputs);

    for (const effect of effects) {
      this.handleEffect(effect);
    }
  }

  handleEffect(effect: Effect): void {
    if (effect.type === 'shot') {
      this.socket.send({
        type: 'shot',
        angle: effect.angle,
        power: effect.power,
      });
    }
  }

  handleMessage = (msg: ServerMessage): void => {
    try {
      if (msg.type === 'identity') {
        updateHandleIdentity(this.universe, msg);
      } else if (msg.type === 'shot') {
        // need to update to let current player know they did a shot?
        // this.handleShotMessage(msg);
      } else if (msg.type === 'initialSnapshot') {
        updateHandleInitialSnapshot(this.universe, msg);
      } else if (msg.type === 'update') {
        updateHandleUpdateSnapshot(this.universe, msg);
      } else if (msg.type === 'joined') {
        updateHandleJoined(this.universe as MatchUniverse, msg);
      } else if (msg.type === 'left') {
        updateHandleLeft(this.universe as MatchUniverse, msg);
      }
    } catch (err) {
      this.stop();
      throw err;
    }
  };

  handleError(err: Error): void {
    this.stop();
    this.socket.disconnect();
    drawErrorScreen(this.ctx, err);
    throw err;
  }

  render(lagOffset: number): void {
    draw(this.ctx, this.universe, this.socket.ping, lagOffset);
  }
}
