import {
  parseServerMessage,
  ServerMessage,
} from '../../universal/messages/ServerMessage';
import { ClientMessage } from '../../universal/messages/ClientMessage';

export class Socket {
  private ws!: WebSocket;
  private onMessage?: (msg: ServerMessage) => void;

  ping = 0;
  private pingInterval?: NodeJS.Timeout;
  private pingTime: number | null = null;

  connect(): void {
    if (process.env.SOCKET_HOST) {
      this.ws = new WebSocket(process.env.SOCKET_HOST);
    } else {
      // this /_s proxy simplifies dev server usage behind proxies for
      // testing via e.g. ngrok
      const proto = document.location.protocol === 'https:' ? 'wss' : 'ws';
      const root = document.location.host;
      this.ws = new WebSocket(`${proto}:${root}/_s`);
    }

    this.ws.onopen = (): void => {
      console.log('ws open');
      this.pingInterval = setInterval(() => {
        if (this.pingTime) {
          // TODO: time out eventually? TCP says we should get a reply tho
          return;
        }
        this.pingTime = performance.now();
        this.send({ type: 'ping' });
      }, 1000);
    };
    this.ws.onmessage = (evt): void => {
      this.handleMessage(evt.data);
    };
    this.ws.onclose = (): void => {
      console.log('ws closed');
      clearInterval(this.pingInterval!);
    };
  }

  disconnect(): void {
    this.ws.close();
  }

  bindOnMessage(fn: (msg: ServerMessage) => void): void {
    this.onMessage = fn;
  }

  send(msg: ClientMessage): void {
    this.ws.send(JSON.stringify(msg));
  }

  handleMessage(data: string): void {
    let obj;
    try {
      obj = JSON.parse(data);
    } catch (err) {
      console.error(`invalid json from server ${data}`);
      return;
    }
    const message = parseServerMessage(obj);

    if (!message) {
      return;
    }

    if (!this.onMessage) {
      throw new Error(
        'tried to handle ws message but no onMessage listener bound'
      );
    }

    if (message.type === 'pong') {
      this.ping = performance.now() - this.pingTime!;
      this.pingTime = null;
    }

    this.onMessage(message);
  }
}
