import { Universe } from '../clientModels';
import { Effect } from '../effects';
import { InputValues } from '../../util/Inputter';
import { updateMatchInput } from './updateMatchInput';
import * as V from '../../../universal/vectorMaths';
import { RUNAHEAD_FRAMES } from '../../../universal/constants';

/*
runahead frames design:
goal: on server frame n, client should be processing frame n-3
- why? we want to avoid world pauses caused by server -> client latency
- by running behind, we get 3 frames of time to receive the new frame before we have to pause
- the downside is latency of player movements, of course, and we may fix that with client-side physics for the current player
  - because of this, runahead frames and the simulation frame should maybe live in their own space?

- so a problem with this strategy is that if we just store the just-received snapshot as "next," but the next snapshot is < runahead frames away, things get weird, i think

e.g. if server sends on frames 1 and 4, but client gets frame 4 on frame 2, client will start lerping towards frame 4 instead of frame 1?
[1] 2 3  [4]  5  6 7
         [1] [2] 3 4

so i _think_ we need a queue instead. the logic should be

const currentFrame = actual current frame - runahead frames;
const snapshotQueue = snapshotQueue.filter((s) => s.frame >= currentFrame);
const nextSnapshot = snapshotQueue[0];
*/

export function updateTick(
  universe: Universe,
  dt: number,
  inputs: InputValues
): Effect[] {
  const effects: Effect[] = [];

  if (universe.currentState === 'inMatch') {
    const currentFrame = universe.match.frame - RUNAHEAD_FRAMES;

    let next = universe.match.snapshotQueue[0];
    if (!next) {
      // waiting on first update to come in
      return effects;
    }

    if (next.frame < currentFrame) {
      universe.match.lastSnapshot = universe.match.snapshotQueue[0];
      universe.match.snapshotQueue.splice(0, 1);
      next = universe.match.snapshotQueue[0];
    }

    if (!next) {
      console.log('waiting for frame');
      // pause game until we get the next frame
      // [some day could do dead reckoning]
      return effects;
    }

    const last = universe.match.lastSnapshot;

    if (currentFrame < last.frame) {
      // we're behind so just skip on forward
      universe.match.frame = last.frame;
    }

    // lerp towards next snapshot
    for (const nextBall of next.balls) {
      const curBall = universe.match.balls[nextBall.playerId];
      if (!curBall) {
        console.warn(`missing ball in next snapshot ${nextBall.playerId}`);
        continue;
      }
      const lastBall = last.balls.find((b) => nextBall.playerId === b.playerId);
      if (!lastBall) {
        curBall.position.x = nextBall.position.x;
        curBall.position.y = nextBall.position.y;
        continue;
      }

      if (V.magnitude(V.subtract(nextBall.position, lastBall.position)) > 20) {
        // don't lerp it if it moves a huge distance (like getting respawned)
        // this is a temp fix that should probably be replaced with some
        // kind of didRespawn counter or something
        curBall.position.x = nextBall.position.x;
        curBall.position.y = nextBall.position.y;
        continue;
      }

      const lerpF = (currentFrame - last.frame) / (next.frame - last.frame);

      if (lerpF > 1 || lerpF < 0) {
        console.warn(
          'weird lerpF',
          lerpF,
          currentFrame,
          last.frame,
          next.frame
        );
      }

      curBall.position = V.lerp(lastBall.position, nextBall.position, lerpF);
      curBall.angle =
        lastBall.angle + (nextBall.angle - lastBall.angle) * lerpF;

      // XXX: this is kind of a weird thing...
      // could feel extra laggy
      if (
        universe.currentPlayer &&
        nextBall.playerId === universe.currentPlayer.id
      ) {
        universe.match.inputState.canShoot = lastBall.canShoot;
      }
    }

    const didShoot = updateMatchInput(dt, universe.match, inputs);
    if (didShoot) {
      effects.push({
        type: 'shot',
        angle: universe.match.inputState.aimDirection,
        power: universe.match.inputState.shotPower,
      });
    }

    universe.match.frame += 1;
  }
  return effects;
}
