import sample from 'lodash.sample';

import { GAME_WIDTH, GAME_HEIGHT } from './constants';
import { Level, Vector2 } from './models';

export const HOLE_WIDTH = 8;
export const HOLE_WALL_HEIGHT = 8;
export const HOLE_HEIGHT = 12;
export const HOLE_SENSOR_HEIGHT = 6;

const colors = [
  'yellow',
  'pink',
  'limegreen',
  'skyblue',
  'orange',
  'red',
  'white',
];

/* get an int between min and max inclusive */
function randInt(min: number, max: number): number {
  return Math.floor(Math.random() * (max - min + 1)) + min;
}

function shuffle<T>(a: T[]): void {
  for (let i = a.length; i; i -= 1) {
    const j = Math.floor(Math.random() * i);
    const x = a[i - 1];
    a[i - 1] = a[j];
    a[j] = x;
  }
}

export function getSegmentWidths(
  totalWidth: number,
  minWidth: number,
  maxWidth: number
): number[] {
  const widths = [];
  let remainingWidth = totalWidth;

  while (remainingWidth > 0) {
    if (remainingWidth < maxWidth) {
      maxWidth = remainingWidth;
    }

    let width = randInt(minWidth, maxWidth);

    // if this segment would leave us with < minWidth remaining width, just make this segment the
    // entire remaining width
    if (remainingWidth - width < minWidth) {
      width = remainingWidth;
    }

    widths.push(width);
    remainingWidth -= width;
  }

  // shuffle widths so it's not biased towards having smaller segments at the end
  shuffle(widths);

  return widths;
}

const testLevel: Level = {
  points: [
    { x: 0, y: 200 },
    { x: GAME_WIDTH, y: 200 },
  ],
  hole: { x: 100, y: 200 },
  spawn: { x: 50, y: 200 },
  terrainColor: 'white',
};

export function levelGen(): Level {
  // this is a pretty lame hack, but...
  if (process.env.MANYGOLF_TEST_LEVEL) {
    return testLevel;
  }

  const segmentWidths = getSegmentWidths(GAME_WIDTH, 30, 100);
  const numSegments = segmentWidths.length;

  const spawnSegment = randInt(1, Math.floor(numSegments / 3));
  const holeSegment = numSegments - randInt(1, Math.floor(numSegments / 3));

  const points: Vector2[] = [];
  let spawnX: number;
  let spawnY: number;
  let holeX: number;
  let holeY: number;

  const minY = 120;
  const maxY = GAME_HEIGHT - 50;

  for (let idx = 0; idx <= numSegments; idx++) {
    const segmentWidth = segmentWidths[idx - 1];

    let x: number;
    let y: number;

    if (idx === 0) {
      x = 0;
    } else {
      x = points[idx - 1].x + segmentWidth;
    }

    if (x > GAME_WIDTH) {
      x = GAME_WIDTH;
    }

    if (idx === 0) {
      y = randInt(minY + 50, maxY - 50);
    } else {
      const prevY = points[idx - 1].y;

      // special-case flat section
      if (randInt(1, 3) === 1 && prevY !== points[idx - 2]?.y) {
        y = prevY;
      } else {
        let yDiff =
          randInt(20, 50) * (Math.round(Math.random()) === 0 ? -1 : 1);

        // if we'd go out of screen margin, just flip in the opposite direction
        if (prevY + yDiff < minY || prevY + yDiff > maxY) {
          yDiff *= -1;
        }

        y = prevY + yDiff;
      }
    }

    if (idx === spawnSegment) {
      spawnX = x - Math.round(segmentWidth / 2);
      y = points[idx - 1].y;
      spawnY = y;
    }

    if (idx === holeSegment) {
      holeX = x - Math.round(segmentWidth / 2);
      y = points[idx - 1].y;
      holeY = y;
    }

    points.push({ x, y });
  }

  const level = {
    points,
    hole: { x: holeX!, y: holeY! },
    spawn: { x: spawnX!, y: spawnY! },
    terrainColor: sample(colors)!,
  };

  return level;
}

interface PointsWithHole {
  beforeHole: Vector2[];
  afterHole: Vector2[];
  holePoints: Vector2[];
}

export function getPointsWithHole(level: Level): PointsWithHole {
  const holeLeftX = level.hole.x - HOLE_WIDTH;
  const holeRightX = level.hole.x + HOLE_WIDTH;
  const beforeHole = level.points
    .filter((p) => p.x < level.hole.x)
    .concat([{ x: holeLeftX, y: level.hole.y }]);
  const afterHole = [{ x: holeRightX, y: level.hole.y }].concat(
    level.points.filter((p) => p.x > level.hole.x)
  );

  const holePoints = [
    { ...beforeHole.slice(-1)[0] },
    { x: holeLeftX, y: level.hole.y + HOLE_WALL_HEIGHT },
    { x: holeLeftX + HOLE_WIDTH, y: level.hole.y + HOLE_HEIGHT },
    { x: holeRightX, y: level.hole.y + HOLE_WALL_HEIGHT },
    { ...afterHole[0] },
  ];

  return { beforeHole, afterHole, holePoints };
}
