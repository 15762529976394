import memoize from 'micro-memoize';
import { GAME_HEIGHT, GAME_WIDTH } from '../../universal/constants';
import { getScale } from './getScale';

// TODO: should be able to type check this better once typescript 4.0 lands
// https://github.com/microsoft/TypeScript/pull/39094
export function createMemoizedRender<
  T extends (ctx: CanvasRenderingContext2D, ...args: never[]) => void
>(fn: T): T {
  const memoized = memoize((...args: never[]): HTMLCanvasElement => {
    const scale = getScale();
    // create canvas
    const canvas = document.createElement('canvas');
    canvas.width = scale * GAME_WIDTH;
    canvas.height = scale * GAME_HEIGHT;
    const ctx = canvas.getContext('2d')!;
    ctx.setTransform(1, 0, 0, 1, 0, 0);
    ctx.scale(scale, scale);

    // run draw function on canvas
    fn(ctx, ...args);
    return canvas;
  });
  const draw = (ctx: CanvasRenderingContext2D, ...args: never[]): void => {
    const canvas = memoized(...args);
    ctx.drawImage(canvas, 0, 0, GAME_WIDTH, GAME_HEIGHT);
  };
  return draw as T;
}
