import { MatchUniverse, Universe } from '../clientModels';
import {
  ServerInitialSnapshotMessage,
  ServerUpdateMessage,
} from '../../../universal/messages/ServerMessage';

function toMap<T>(items: T[], key: keyof T): { [k: string]: T } {
  const map: { [k: string]: T } = {};
  for (const item of items) {
    map[`${item[key]}`] = item;
  }
  return map;
}

export function updateHandleInitialSnapshot(
  universe: Universe,
  snapshot: ServerInitialSnapshotMessage
): void {
  universe.currentState = 'inMatch';

  // this is weird...
  (universe as MatchUniverse).match = {
    frame: snapshot.frame,
    level: snapshot.level,
    balls: toMap(snapshot.balls, 'playerId'),
    players: toMap(snapshot.players, 'id'),
    // strokes: snapshot.strokes,

    // TODO: will want to merge this in the future if snapshot is used for partial updates
    inputState: {
      aimDirection: 0,
      canShoot: true,
      shooting: false,
      shotPower: 0,
    },

    lastSnapshot: {
      type: 'update',
      frame: 0,
      balls: snapshot.balls,
    },
    snapshotQueue: [],
  };
}

export function updateHandleUpdateSnapshot(
  universe: Universe,
  snapshot: ServerUpdateMessage
): void {
  if (universe.currentState !== 'inMatch') {
    return;
  }

  universe.match.snapshotQueue.push(snapshot);
}
