import { GAME_WIDTH, GAME_HEIGHT } from '../../universal/constants';
import { Level } from '../../universal/models';
import { createMemoizedRender } from '../util/createMemoizedRender';
import { getPointsWithHole } from '../../universal/levelGen';

export const drawGround = createMemoizedRender(
  (ctx: CanvasRenderingContext2D, level: Level) => {
    ctx.fillStyle = 'black';

    const { beforeHole, holePoints, afterHole } = getPointsWithHole(level);
    const points = beforeHole.concat(holePoints).concat(afterHole);

    ctx.beginPath();
    const firstPoint = points[0];
    ctx.moveTo(firstPoint.x, firstPoint.y);

    points.slice(1).forEach((point) => {
      ctx.lineTo(point.x, point.y);
    });

    // draw a complete shape so fill works
    // add padding so the outline stroke doesn't show up
    const groundLineWidth = 3;
    ctx.lineTo(GAME_WIDTH + groundLineWidth, points.slice(-1)[0].y);
    ctx.lineTo(GAME_WIDTH + groundLineWidth, GAME_HEIGHT + groundLineWidth);
    ctx.lineTo(-groundLineWidth, GAME_HEIGHT + groundLineWidth);
    ctx.lineTo(-groundLineWidth, points[0].x);

    ctx.lineWidth = groundLineWidth;
    ctx.strokeStyle = level.terrainColor;
    ctx.stroke();
    ctx.fill();
    ctx.closePath();

    // draw a dot at each segment point
    for (const point of points) {
      ctx.fillStyle = level.terrainColor;
      ctx.beginPath();
      ctx.arc(point.x, point.y, 2, 0, 2 * Math.PI);
      ctx.fill();
      ctx.closePath();
    }
  }
);
