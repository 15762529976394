import { Match } from '../clientModels';
import { InputValues } from '../../util/Inputter';
import { keyCodes } from '../../util/keyCodes';

const SHOT_POWER_PER_MS = 1 / 1000;
// 120 degrees per s
const AIM_DIRECTION_STEP_PER_MS = (120 * (Math.PI / 180)) / 1000;

export function updateMatchInput(
  dt: number,
  match: Match,
  inputs: InputValues
): boolean {
  let didShoot = false;

  // aim meter
  if (match.inputState.canShoot) {
    if (inputs.includes(keyCodes.a) || inputs.includes(keyCodes.leftArrow)) {
      match.inputState.aimDirection -=
        (AIM_DIRECTION_STEP_PER_MS * dt) % (2 * Math.PI);
    }
    if (inputs.includes(keyCodes.d) || inputs.includes(keyCodes.rightArrow)) {
      match.inputState.aimDirection +=
        (AIM_DIRECTION_STEP_PER_MS * dt) % (2 * Math.PI);
    }
  }

  // power bar
  if (match.inputState.shooting) {
    if (inputs.includes(keyCodes.space)) {
      match.inputState.shooting = true;
      match.inputState.shotPower += dt * SHOT_POWER_PER_MS;
    } else {
      match.inputState.shooting = false;
      didShoot = true;
    }
  } else if (match.inputState.canShoot) {
    if (inputs.includes(keyCodes.space)) {
      match.inputState.shooting = true;
      match.inputState.shotPower = 0;
    }
  }

  return didShoot;
}
