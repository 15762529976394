import { Universe } from '../state/clientModels';
import { GAME_WIDTH, GAME_HEIGHT } from '../../universal/constants';
import { drawGround } from './drawGround';
import { drawBall } from './drawBall';
import { drawShotUI } from './drawShotUI';
import { drawPing } from './drawPing';

export function draw(
  ctx: CanvasRenderingContext2D,
  universe: Universe,
  ping: number,
  lagOffset: number
): void {
  ctx.fillStyle = 'black';
  ctx.fillRect(0, 0, GAME_WIDTH, GAME_HEIGHT);

  drawPing(ctx, ping);

  if (universe.currentState === 'inMatch') {
    drawGround(ctx, universe.match.level);
    for (const ball of Object.values(universe.match.balls)) {
      drawBall(ctx, ball);
    }
    drawShotUI(ctx, universe);
  } else if (universe.currentState === 'connecting') {
    // TODO
    ctx.font = '20px monospace';
    ctx.textAlign = 'center';
    ctx.fillStyle = 'white';
    ctx.fillText('connecting ...', GAME_WIDTH / 2, GAME_HEIGHT / 2);
  } else if (universe.currentState === 'disconnected') {
    ctx.font = '20px monospace';
    ctx.textAlign = 'center';
    ctx.fillStyle = 'white';
    ctx.fillText('disconnected!', GAME_WIDTH / 2, GAME_HEIGHT / 2);
  }
}
