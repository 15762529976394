import { Level, Vector2 } from '../../universal/models';
import { ServerUpdateMessage } from '../../universal/messages/ServerMessage';

export interface Player {
  id: string;
  name: string;
  color: string;
}

export interface Ball {
  playerId: string;
  position: Vector2;
  angle: number;
}

export interface Match {
  frame: number;
  balls: { [playerId: string]: Ball };
  players: { [playerId: string]: Player };
  level: Level;
  // strokes: { [playerId: string]: number };
  inputState: {
    /** whether the player can currently take a shot (ball is stopped) */
    canShoot: boolean;
    /** whether the player is mid-shot (has started holding space) */
    shooting: boolean;
    /** number from 0 to 1 indicating current shot power % */
    shotPower: number;
    /** in radians, relative to facing forward */
    aimDirection: number;
  };
  lastSnapshot: ServerUpdateMessage;
  snapshotQueue: ServerUpdateMessage[];
}

export interface BaseUniverse {
  currentState: 'connecting' | 'inMatch' | 'disconnected';
  currentPlayer?: {
    id: string;
    name: string;
  };
}

export interface ConnectingUniverse extends BaseUniverse {
  currentState: 'connecting';
}

export interface MatchUniverse extends BaseUniverse {
  currentState: 'inMatch';
  match: Match;
}

export interface DisconnectedUniverse extends BaseUniverse {
  currentState: 'disconnected';
}

export type Universe =
  | ConnectingUniverse
  | MatchUniverse
  | DisconnectedUniverse;

export function initUniverse(): Universe {
  return {
    currentState: 'connecting',
  };
}
