import { Ball } from '../state/clientModels';
import { createMemoizedRender } from '../util/createMemoizedRender';
import { BALL_RADIUS } from '../../universal/constants';

export const drawBallSprite = createMemoizedRender(
  (ctx: CanvasRenderingContext2D, color: string) => {
    // ball border width
    ctx.lineWidth = 1;

    ctx.fillStyle = color;
    ctx.strokeStyle = color;

    ctx.translate(BALL_RADIUS, BALL_RADIUS);

    ctx.beginPath();
    ctx.arc(0, 0, BALL_RADIUS, 0, 2 * Math.PI);
    ctx.fill();
    ctx.closePath();

    ctx.beginPath();
    ctx.arc(0, 0, BALL_RADIUS - ctx.lineWidth / 2, 0, 2 * Math.PI);
    ctx.stroke();
    ctx.closePath();

    ctx.beginPath();
    ctx.strokeStyle = 'black';
    ctx.moveTo(0, 0);
    ctx.lineTo(BALL_RADIUS, 0);
    ctx.stroke();
    ctx.closePath();
  }
);

export function drawBall(ctx: CanvasRenderingContext2D, ball: Ball): void {
  ctx.save();
  ctx.translate(ball.position.x, ball.position.y);
  ctx.rotate(ball.angle % (2 * Math.PI));
  ctx.translate(-BALL_RADIUS, -BALL_RADIUS);
  drawBallSprite(ctx, 'white'); // TODO: color
  ctx.restore();
}
