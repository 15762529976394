import { ServerLeftMessage } from '../../../universal/messages/ServerMessage';
import { MatchUniverse } from '../clientModels';

export function updateHandleLeft(
  universe: MatchUniverse,
  msg: ServerLeftMessage
): void {
  delete universe.match.players[msg.playerId];
  delete universe.match.balls[msg.playerId];
}
